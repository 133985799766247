import React from 'react'

import App from './main'

import { BrowserRouter } from 'react-router-dom'
import ErrorBoundary from '../containers/ErrorBoundary'
import { ThemeProvider } from 'styled-components'
import theme from '../components/theme'

const Root = () => (
    <ErrorBoundary>
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </BrowserRouter>
    </ErrorBoundary>
)

export default Root
