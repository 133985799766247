import React, { Suspense, lazy } from 'react'
import { Route, Routes } from 'react-router-dom'

const SaveToJapa = lazy(() => import('../../components/savetojapa'))
const NotFound = lazy(() => import('../../components/errorBoundary/NotFound'))

const MainRoutes = () => {
    const Loader = () => <div></div>
    return (
        <Suspense fallback={<Loader />}>
            <Routes>
                <Route path="/savetojapa" element={<SaveToJapa />} />
                <Route path="/*" element={<NotFound />} />
            </Routes>
        </Suspense>
    )
}
export default MainRoutes
